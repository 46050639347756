import React from 'react';
import styled from 'styles/styled-components';
import { ProductBrand } from 'types/schema';
import media from 'utils/mediaStyle';
import { getInitials } from 'utils/getInitialsBrandName';
import Link from 'antd/es/typography/Link';
import { generateUrlBrandDetail } from '../../../utils/generateBrandDetailUrl';

interface IProps {
  brands: ProductBrand[];
  lang: string;
}

const BrandListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 16px;
  ${media.sm`
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 20px;
    column-gap: 24px;
`}
  ${media.md`
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 20px;
    column-gap: 24px;
`}
  ${media.lg`
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 20px;
    column-gap: 24px;
    `}
`;

const BrandItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  background-color: #ffffff;
  padding: 8px;
  border: 1px solid #eaeaeb;
  border-radius: 8px;
  &:hover {
    box-shadow:
      0 1px 3px 0 rgb(0 0 0 / 0.1),
      0 1px 2px -1px rgb(0 0 0 / 0.1);
  }
  ${media.lg`
    padding: 12px;
  `}
`;

const ImageContainer = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  border: 1px solid #eaeaeb;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #242527;
`;
const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 6px;
`;

const BrandName = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #000000;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const BrandCategories = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;
const BrandInfoWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BrandList = ({ brands, lang }: IProps) => {
  return (
    <BrandListWrapper>
      {brands.map((brand) => (
        <Link href={generateUrlBrandDetail(brand.id, brand?.name as string, lang)} key={brand.id}>
          <BrandItem>
            <ImageContainer>
              {brand?.imageUrl ? <Image src={brand?.imageUrl} alt={brand?.name} /> : getInitials(brand?.name as string)}
            </ImageContainer>
            <BrandInfoWrapper>
              <BrandName>{brand.name}</BrandName>
              <BrandCategories>{brand?.productCategories?.map((item) => item?.name).join(', ')}</BrandCategories>
            </BrandInfoWrapper>
          </BrandItem>
        </Link>
      ))}
    </BrandListWrapper>
  );
};

export default BrandList;
