import { Spin } from 'antd';
import LoadingIndicator from 'components/LoadingIndicator';
import React, { FC, Fragment } from 'react';
import Helmet from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styles/styled-components';
import { ApplicationRootState } from 'types';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import media from 'utils/mediaStyle';
import renderWithWebDomain from 'utils/renderWithWebDomain';
import { applySearch, fetchMore } from './actions';
import BrandList from './components/BrandList';
import SearchSection from './components/SearchSection';
import { banner, banner_VN, banner_mobile, banner_mobile_VN } from './images';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import selectAllBrandsPage from './selectors';
import { ContainerState } from './types';
import { selectCategories, selectLang } from 'containers/MainLayout/selectors';
import { Category } from 'types/schema';
import { Viewport } from 'components/ViewportProvider';
import translations from 'translations';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 75pt);
  margin-top: 99.66px;
`;

const Content = styled.div`
  padding: 0 16px;
  ${media.md`
    padding: 0;`}

  .infinite-scroll-component {
    overflow: hidden !important;
  }
`;

const Banner = styled.img`
  width: 100%;
  min-height: 120px;
  object-fit: fill;
`;

const Title = styled.h1`
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: #000000;
  ${media.md`
    margin-top: 40px;
    font-size: 24px;
    `}
`;

interface IStateProps {
  allBrandPageState: ContainerState;
  categories: Category[];
  localLang: string;
}

interface DispatchProps {
  onSearch: (data: any) => void;
  onFetchMoreBrands: () => void;
}

type Props = IStateProps & DispatchProps;
const AllBrandsPage: FC<Props> = (props) => {
  const {
    allBrandPageState: { loadingAllBrands, brandsData, search },
    categories,
    onSearch,
    onFetchMoreBrands,
    localLang,
  } = props;

  const locale = localLang;

  return (
    <Fragment>
      <Helmet>
        <title>{translations(messages.allBrands)}</title>
        <meta property="og:url" content={(renderWithWebDomain(process.env.APP_ENV), 'thuong-hieu')} />
        <link rel="canonical" href={(renderWithWebDomain(process.env.APP_ENV), 'thuong-hieu')} />
        <link rel="alternate" href={(renderWithWebDomain(process.env.APP_ENV), 'thuong-hieu')} />
        <meta name="description" content="Tất cả thương hiệu mà Kamereo hợp tác đê bán sản phẩm" />
        <meta property="og:title" content="Tất cả thương hiệu" />
        <meta property="og:type" content="website" />
      </Helmet>
      <MainContainer>
        <Viewport.Consumer>
          {({ width }) => (
            <Banner
              src={
                width >= 768
                  ? locale === 'vi'
                    ? banner_VN
                    : banner
                  : locale === 'vi'
                    ? banner_mobile_VN
                    : banner_mobile
              }
              loading="lazy"
            />
          )}
        </Viewport.Consumer>
        <Content>
          <Title>
            <FormattedMessage {...messages.allBrands} />
          </Title>
          <SearchSection onSearch={onSearch} categories={categories} search={search} />
          <Spin spinning={loadingAllBrands && brandsData?.data?.length === 0}>
            <InfiniteScroll
              dataLength={brandsData?.data?.length || 0}
              next={onFetchMoreBrands}
              hasMore={(brandsData?.data?.length || 0) < brandsData.totalResults}
              loader={loadingAllBrands && <LoadingIndicator />}
              scrollThreshold={0.6}
            >
              <BrandList brands={brandsData?.data || []} lang={locale} />
            </InfiniteScroll>
          </Spin>
        </Content>
      </MainContainer>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  allBrandPageState: selectAllBrandsPage(),
  categories: selectCategories(),
  localLang: selectLang(),
});

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    onSearch: (data: any) => dispatch(applySearch(data)),
    onFetchMoreBrands: () => dispatch(fetchMore()),
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'allBrandsPage',
  reducer: reducer,
});

const withSaga = injectSaga({ key: 'allBrandsPage', saga: saga });

export default compose(withConnect, withReducer, withSaga)(AllBrandsPage);
